
import { Button, Classes, Dialog, H1, H2, H3, H4, HTMLTable, Icon } from "@blueprintjs/core";
import * as React from "react";
import { urls } from "../../global/firebase";
import '../../assets/css/style.css'
import { range } from "lodash";

export  class CustomerInfo extends React.Component{

public state:any={
    coupons:[],
    inActiveCoupons:[],
    yardapikey:'',
    consumer_phone_number:'0000000000',
    nextcoupon:[],
    spendamt:[],
    detail:[],
    modal:false,
    valu:[],
    discounttotal:0

}

public  componentDidMount(): void {
    const params = new URL(window.location.href).searchParams;  
    const yardapikey = params.get("yard_api_key")!; // is the string "Jonathan Smith".
    const Consumerphonenumber = params.get("consumer_phone_number")!; // is the string "Jonathan Smith".
    const val = params.get("val")!; // is the string "Jonathan Smith".
    this.setState({yardapikey,consumer_phone_number:Consumerphonenumber,valu:val.split(',')})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    urlencoded.append("consumer_phone_number", Consumerphonenumber);
    try {
 
   const el:any= document.querySelector('.bp5-navbar')!
     el.style.display = 'none'
            
    } catch (error) {
        console.log(error);
        
    }
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(urls.pointsystem+"p1s5e4_get_consumer_information", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        const arrInactive:any =[]
        const arrActive:any =[]
        let discounttotal = 0;
        result.coupons.map((x:any)=>{
        Object.keys(x).map((l:any)=>{
          
            if(x[l].coupon_used!==undefined && x[l].coupon_used===1){   
              x[l].order = new Date(x[l].issue_date).getTime();         
              arrInactive.push(x[l])
              discounttotal+=x[l].transaction_amount
            }else if(x[l].coupon_used!==undefined && x[l].coupon_used===0){
                x[l].order = new Date(x[l].date_used).getTime();
                arrActive.push(x[l])
            }
        })
        })
        const arrInactivex = arrInactive.sort((a:any,b:any)=>(b.order-a.order))
        const arrActivex = arrActive.sort((a:any,b:any)=>(b.order-a.order))


        
         this.setState({discounttotal,inActiveCoupons:arrInactivex,coupons:arrActivex,nextcoupon:result.next_coupon,spendamt:result.spent_amounts})   
         
          
        })
      .catch(error => console.log('error', error));
}


public render(){
    const styleTh=  {
        padding: '4px',        
        border: '1px solid #000',
        textAlign:'center'as const
    }

    
    const styleThX=  {
        padding: '4px',        
        border: '1px solid #000',
        textAlign:'left'as const

    }

    const styleThY=  {
        padding: '4px',        
        border: '1px solid #000',
        textAlign:'right'as const
    }

    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'15px',
        cursor:'pointer'
    }

const Datetimeformate=(date:any)=>{
    
const da = new Date(date);
const day = da.getUTCDate()<10 ? '0'+da.getUTCDate():da.getUTCDate()
const month = (da.getUTCMonth()+1)<10 ? '0'+(da.getUTCMonth()+1):(da.getUTCMonth()+1)
const year = da.getUTCFullYear()

return `${month}/${day}/${year}`
}

const timeConvertFormat = (time:any)=>{

    const timeString = time
    // Prepend any date. Use your birthday.
    const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
      .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
      return timeString12hr;

}

    const AmountFormate=(x:any)=>{
           
        const options = { 
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          };
            const formatted =  Math.round(Number(x)).toLocaleString('en', options);
            console.log(formatted);
        return formatted
    }

    const prettyMobile = (mobile: string) => {
        const areaCode = mobile.substring(0, 3);
        const prefix = mobile.substring(3, 6);
        const suffix = mobile.substring(6, 10);
      
        return `(${areaCode}) ${prefix}-${suffix}`;
      };
      
const csvDownload=()=>{
    const arr:any = []
    arr.push(['Customer:  '+prettyMobile(this.state.consumer_phone_number),' ',' ',' ',' ',' '])
    arr.push(['Coupon Deduction','Coupon Issue Date','Coupon Expire Date',' ',' ',' '])
    arr.push(['Active Coupon',' ',' ',' ',' ',' '])
    if(this.state.coupons.length>0){        
    this.state.coupons.map((r:any)=>{
        arr.push([valuupdate(r.coupon_percentage+'%'),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),' ',' ',' ']);    
    });

    }else{
        arr.push(['','No active coupons at this time',' ',' ',' ',' ']); 
    }


    if(this.state.nextcoupon.coupon_cap_amount!==undefined){
        
        if(arr.length<=12){
            range(12-arr.length).map(()=>{
                arr.push([' ',' ',' ',' ',' ','']);
            })
        }

        arr[1][4] = 'Next Reward Milestone'
        arr[2][4] = 'Coupon Percentage'
        arr[3][4] = 'Points Earned'
        arr[4][4] = 'Points Needed'
        arr[5][4] = 'Points Needed'
        arr[5][4] = ''
        arr[6][4] = 'Customer Spend & Discount Overview'
        arr[7][4] = 'Last Month Spend'
        arr[8][4] = 'Last 3 Months Spend'
        arr[9][4] = 'Last 6 Months Spend'
        arr[10][4] = 'Last Year Spend'
        arr[11][4] = 'Total Customer Discounts'



        arr[2][5] = valuupdate(this.state.nextcoupon.coupon_percentage+'%')
        arr[3][5] = Math.round(parseInt(this.state.nextcoupon.progress_towards,10))+' Points'
        arr[4][5] = Math.round(parseInt(this.state.nextcoupon.progress_left,10))+' Points'

        if(this.state.spendamt!==undefined){
            arr[7][5] ='$'+Math.round(this.state.spendamt.last_month.toFixed(2))
            arr[8][5] = '$'+Math.round(this.state.spendamt.last_3_months.toFixed(2))
            arr[9][5] = '$'+Math.round(this.state.spendamt.last_6_months.toFixed(2))
            arr[10][5] = '$'+Math.round(this.state.spendamt.last_year.toFixed(2))
            arr[11][5] = '$'+Math.round(this.state.discounttotal)
        }

    }

    arr.push(['Redeemed Coupons',' ',' ',' ',' ',' '])
    arr.push(['Points Before Coupon','Coupon Deduction','Date/Time Coupon Redeemed','Coupon Issue Date','Coupon Expire Date','Customer Discount'])
    if(this.state.inActiveCoupons.length>0){        
    this.state.inActiveCoupons.map((r:any)=>{
        
        arr.push([Math.round(r.amount_spent)+' Points',valuupdate(r.coupon_percentage+'%'),useddate(r),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),'$'+r.transaction_amount]); 
        
    });

    }else{
        arr.push(['','No active coupons at this time',' ',' ',' ',' ']); 
    } 
const seperator = ",";


const csv = [
    ...arr.map((row:any )=>  [0,1,2,3,4,5].map(field => `${row[field]}`).join(seperator))
]

//Export our csv in rows to a csv file
let csvContent = "data:text/csv;charset=utf-8," 
    + csv.join("\n");
var encodedUri = encodeURI(csvContent);
window.open(encodedUri);
       

}

const useddate=(r:any)=>{
const time =  r.time_used!==undefined ? timeConvertFormat(r.time_used) :'' ;
const dat =r.date_used!==undefined ? Datetimeformate(r.date_used) :'';
return  `${dat} ${time} CST`
}


const valuupdate=(ind:any)=>{    
    let wr = '';
    if(this.state.valu.length>0){
        this.state.valu.map((v:any)=>{
if(v.includes(ind)){
    wr=v
}

        })
    }

    return wr;
}


const openmodal=(detail:any)=>{

this.setState({detail:JSON.parse(detail),modal:true})

}

    return(
        <div style={{padding:'13px',fontSize:'17px',textAlign:'left',background:'#fff'}}>

<Dialog
                title="Coupon Trasaction Details"
                icon="info-sign"
                isOpen={this.state.modal}
                onClose={()=>this.setState({modal:false})}>
                <div className={Classes.DIALOG_BODY} style={{overflow:'auto'}}>

                    {this.state.detail.length>0?<HTMLTable border={1}>
                    <tr>
                            <th>TransRno</th>
                            <th>DetailNo</th>
                            <th>DiscountRate</th>
                            <th>DiscountName</th>
                            <th>DiscountAmount</th>
                            <th>PriceList</th>
                            <th>PartName</th>
                            <th>PartQty</th>
                            <th>PartTotal</th>
                            <th>CoreQty</th>
                            <th>CoreTotal</th>
                        
                           
                        </tr>
                     { this.state.detail.map((x:any)=>(  <tr>
                        <td>{x.TransRno}</td>
                        <td>{x.DetailNo}</td>
                        <td>{x.DiscountRate}</td>
                        <td>{x.DiscountName}</td>
                        <td>${AmountFormate(x.DiscountAmount)}</td> 
                        <td>{x.PriceList}</td>
                        <td>{x.PartName}</td>
                        <td>{x.PartQty}</td>
                        <td>${AmountFormate(x.PartTotal)}</td>
                        <td>{x.CoreQty}</td>
                        <td>{x.CoreTotal}</td>
                                        
                        </tr>))}
                      
         

                    </HTMLTable>:''}
          </div>
          </Dialog>

            {/* <H1>Yard Api Key: {this.state.yardapikey}</H1> */}
            <div style={{position:'fixed',padding:'5px',border:'1px solid #000',top:'0px',background:'#fff'}}>
            Download Csv:&nbsp; <Button intent="success" onClick={()=>csvDownload()} title="download csv"><Icon icon='download'/></Button>

            </div>             <H1>Customer Phone Number: {prettyMobile(this.state.consumer_phone_number)}</H1>
            <div className="row-full-width">
            <div className="w-71">
         <div style={{margin:'10px',background:'slategray'}}>    
              <div>
              <div style={{background: 'antiquewhite'}}>
                    <span style={stylespan2}> <H4 style={{color:'green'}}>&nbsp;Active Coupons</H4></span>
                        <p  style={{overflow:'auto'}}>                       
                                <tr>                                
                                   {/* <th style={styleTh}>Subtotal</th> */}
                                    <th style={styleTh}>Coupon Deduction</th>
                                    <th style={styleTh}>Coupon Issue Date</th>
                                    <th style={styleTh}>Coupon Expire Date</th>
                                   
                                </tr>
                            {this.state.coupons.length>0?  this.state.coupons.map((r:any)=>(
                                       
                                <tr>
                                    {/* <td style={styleTh}>${r.amount_spent}</td> */}
                                    <td style={styleTh}><H4>{valuupdate(r.coupon_percentage+'%')}</H4></td>
                                    <td style={styleTh}><H4>{Datetimeformate(r.issue_date)}</H4></td>
                                    <td style={styleTh}><H4>{Datetimeformate(r.expiry_date)}</H4></td>
                                </tr>

                            )) :  <tr><td colSpan={3}>No active coupons at this time</td></tr> }                        
                        </p>
                        </div>    

                        {/* INACTIVE COUPONS */}
                        <div style={{background: 'antiquewhite'}}>
                       <span style={stylespan2}> <H4 style={{color:'red'}}>&nbsp;Redeemed Coupons</H4></span>
                        <p  style={{overflow:'auto'}}>    
                            <HTMLTable >  
                                <thead>         
                                <tr>                                
                                   <th style={styleTh}>Points Before Coupon</th>
                                    <th style={styleTh}>Coupon Deduction</th>                                    
                                    <th style={styleTh}>Date/Time Coupon Redeemed</th>
                                    <th style={styleTh}>Coupon Issue Date</th>
                                    <th style={styleTh}>Coupon Expire Date</th>
                                    <th style={styleTh}>Customer Discount </th>
                                    {/* <th style={styleTh}>SubTotal</th> */}
                                    {/* <th style={styleTh}>Detail</th> */}
                                </tr>
                                </thead>  
                                
                                <tbody>   
                            {this.state.inActiveCoupons.length>0? this.state.inActiveCoupons.map((rx:any)=>(
                         
                                <tr>
                                    <td style={styleTh}>{AmountFormate(rx.amount_spent)+' Points'}</td>
                                    <td style={styleTh}>{valuupdate(rx.coupon_percentage+'%')}</td>
                                    <td style={styleTh}>{rx.date_used!==undefined ? Datetimeformate(rx.date_used) :''}  {rx.time_used!==undefined ? timeConvertFormat(rx.time_used) :''} CST</td>
                                    <td style={styleTh}>{Datetimeformate(rx.issue_date)}</td>
                                    <td style={styleTh}>{Datetimeformate(rx.expiry_date)}</td>
                                    <td style={styleTh} title={rx.transaction_id!==undefined ? rx.transaction_id :''}>${rx.transaction_amount!==undefined ? AmountFormate(rx.transaction_amount) :''}</td>
                                    {/* <td style={styleTh}>${AmountFormate(rx.p1s5e3_subtotal)}</td> */}
                                    {/* <td style={styleTh} onClick={()=>openmodal(rx.p1s5e3_transaction_details)}><Icon icon='eye-open'/></td> */}

                                </tr>  
                                                                 
                            )):   <tr><td colSpan={3}>No redeemed coupons at this time</td></tr> }  

                            <tr>
                            <td style={styleTh} colSpan={4}></td>
                            <td style={styleTh}><b>Total Customer Discounts</b></td>
                            <td style={styleTh}><b>${AmountFormate(this.state.discounttotal)}</b></td>
                            {/* <td style={styleTh}></td> */}
                            {/* <td style={styleTh}></td> */}
                            </tr>    
                               </tbody>     
                               </HTMLTable>                  
                        </p>
                        </div>                        
                        </div>            
                
                
                        </div>

             </div>
            <div className="w-31">
                       <div style={{margin:'10px',background:'ghostwhite',padding:'4px'}}>
                        <H4>Next Reward Milestone</H4>
                        {this.state.nextcoupon.coupon_cap_amount!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                              <HTMLTable>
                                <tr>
                                <th style={styleThX}><H4>Next Coupon Value</H4></th>
                                <td style={styleThY}><H4>{this.state.nextcoupon.coupon_percentage}%</H4></td>
                                </tr>
                                
                                 <tr>
                                <th style={styleThX}><H4>Points Earned</H4></th>
                                <td style={styleThY}><H4>{AmountFormate(parseInt(this.state.nextcoupon.progress_towards,10))} Points</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Points Needed</H4></th>
                                <td style={styleThY}><H4>{AmountFormate(parseInt(this.state.nextcoupon.progress_left,10))} Points</H4></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                        </div>  


                         <div  style={{margin:'10px',background:'skyblue',padding:'4px'}}>
                        <H4>Customer Spend & Discount Overview</H4>
                        {this.state.spendamt.last_3_months!==undefined    ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                 <HTMLTable>
                              <tr>
                                <th style={styleThX}>Last Month Spend</th>
                                <td style={styleThY}>${AmountFormate(this.state.spendamt.last_month.toFixed(2))}</td>
                                </tr>
                                <tr>
                                <th style={styleThX}>Last 3 Months Spend</th>
                                <td style={styleThY}>${AmountFormate(this.state.spendamt.last_3_months.toFixed(2))}</td>
                                </tr>
                                <tr>
                                <th style={styleThX}>Last 6 Months Spend</th>
                                <td style={styleThY}>${AmountFormate(this.state.spendamt.last_6_months.toFixed(2))}</td>
                                </tr>                              
                                <tr>
                                <th style={styleThX}>Last Year Spend</th>
                                <td style={styleThY}>${AmountFormate(this.state.spendamt.last_year.toFixed(2))}</td>
                                </tr>

                                <tr>
                                <th style={styleThX}>Total Customer Discounts</th>
                                <td style={styleThY}>${AmountFormate(this.state.discounttotal)}</td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                    </div>                    
             
                </div>
                    </div>
        </div>
    )
}


}


